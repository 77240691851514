@import '../../constants.scss';

.page__title-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 12vh;
    width: 100%;

    .page__logo {
        height: 2.5rem;
        margin-right: 1.5rem;
    }

    .download__android {
        height: 3rem;
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
        z-index: 100;
    }

    .page__title {
        color: $white;
        display: flex;
        flex-direction: row;
        font-size: 2rem;

        .page__title__first {
            font-weight: 300;
            opacity: 0.5;
            margin-right: 0.5rem;
        }

        .page__title__second {
            font-family: 'Recovery', sans-serif;
            position: relative;

            .tagline {
                top: 2.25rem;
                content: '...without worry';
                font-family: 'Lato', sans-serif;
                font-size: 1rem;
                font-style: italic;
                font-weight: 300;
                margin-right: 0.25rem;
                opacity: 0.5;
                position: absolute;
                right: 0;
                text-align: right;
                width: 15rem;
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .page__title-wrapper {
        .download__android {
            height: 3rem;
            position: fixed !important;
            top: 85vh;
        }
    }
}
