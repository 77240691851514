$white: #FFFFFF;
$grey_10: #efefef;
$grey_20: #e3e3e3;
$grey_30: #cccccc;
$grey_40: #afafaf;
$grey_60: #8d8d8d;
$grey_80: #696969;
$grey_90: #444444;
$black: #2D2D2D;
$green: #37b34e;
$blue: #1a98d3;
$blue_dark: #32315e;
$yellow: #d6d455;

@font-face {
    font-family: 'Recovery';
    src: url('./assets/Recovery-Bold.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;1,400&display=swap');