@import '../../constants.scss';

.landing {
    .header {
        height: 14vh;
    }

    .wrapper {
        display: flex;
        height: 100%;
        margin: auto;
        width: 80vw;

        .title {
            font-size: 2.5rem;
        }

        .subtitle {
            color: $grey_60;
            font-size: 1.5rem;
            font-style: italic;
            font-weight: 300;
            margin-top: 0.25rem;
        }
    }

    .hero {
        background: url('../../assets/back_cover_blurred.jpg') 100% 100%;
        background-position: bottom;
        display: flex;
        height: 80vh;

        .wrapper {
            align-items: center;
        }

        .hero__text-panel {
            text-align: left;
            width: 40vw;
        }

        .hero__image-panel {
            width: 40vw;

            .devices {
                height: auto;
                position: relative;
                width: 40rem;
                z-index: 1;
            }
        }
    }

    .explaination {
        background-color: $blue;
        height: 60vh;
        padding: 4rem;
        position: relative;

        &::before {
            background-color: $white;
            content: '';
            clip-path: polygon(0 100%, 100% 0, 100% 100%);
            height: 8rem;
            opacity: 0.75;
            position: absolute;
            top: -8rem;
            left: 0;
            width: 100%;
        }

        &::after {
            background-color: $blue;
            content: '';
            clip-path: polygon(0 100%, 100% 0, 100% 100%);
            height: 5rem;
            position: absolute;
            top: -5rem;
            left: 0;
            width: 100%;
        }

        .wrapper {
            align-items: flex-start;
            flex-direction: column;
            text-align: left;

            .title {
                margin-left: 2.5rem;
            }

            .panels {
                display: flex;
                width: 100%;

                .panel {
                    display: block;
                    margin: 0 1rem;
                    padding: 1.5rem;
                    width: calc(33% - 5rem);

                    .material-icons {
                        align-items: center;
                        // border-bottom: 1px solid $white;
                        display: flex;
                        font-size: 2rem;
                        opacity: 0.5;
                        justify-content: center;
                        margin-bottom: 1.5rem;
                        padding: 1rem;
                        width: 100%;
                    }

                    .panel__title {
                        font-size: 1.5rem;
                        font-weight: 400;
                        margin-bottom: 0.5rem;
                    }

                    .panel__description {
                        font-size: 1.1rem;
                        font-style: italic;
                        font-weight: 300;
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    .footer {
        background-color: $black;
        position: relative;

        &::before {
            background-color: $white;
            content: '';
            clip-path: polygon(0 100%, 100% 0, 100% 100%);
            height: 8rem;
            opacity: 0.75;
            position: absolute;
            top: -8rem;
            left: 0;
            width: 100%;
        }

        &::after {
            background-color: $black;
            content: '';
            clip-path: polygon(0 100%, 100% 0, 100% 100%);
            height: 5rem;
            position: absolute;
            top: calc(-5rem + 1px);
            left: 0;
            width: 100%;
        }

        .wrapper {
            justify-content: center;
            padding: 1rem;

            .textWrapper {
                color: $grey_40;
                margin-top: -2rem;
                z-index: 2;

                a {
                    color: $blue;
                    margin-left: 0.25rem;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .landing {
        width: 100%;

        .wrapper {
            flex-direction: column !important;

            .hero__text-panel {
                margin-top: 4rem;
                width: 100%;
            }

            .hero__image-panel {
                .devices {
                    width: 60vw;
                }
            }
        }

        .explaination {
            height: auto;
            padding: 0.5rem;
            padding-bottom: 10rem;

            .wrapper {
                width: 100%;

                .panels {
                    flex-direction: column;
                    width: 100%;

                    .panel {
                        margin: 0;
                        width: calc(100% - 2rem);

                        .material-icons {
                            width: calc(100% - 2rem);
                        }
                    }
                }
            }
        }
    }
}
