@import '../../constants.scss';

.page {
    background: url('../../assets/back_cover_blurred.jpg') 100% 100%;
    background-position: bottom;
    position: relative;
    height: 100vh;
    overflow: hidden;
    width: 100%;

    &:before {
        background-color: $blue;
        content: '';
        clip-path: polygon(0 90%, 100% 40%, 100% 100%, 0 100%);
        height: 100%;
        left: 0;
        opacity: 0.33;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .header {
        height: 12vh;
    }

    .map {
        height: 83vh;
        padding: 2.5vh 2.5vw;
        width: calc(100% - 5vw);
    }
}

@media screen and (max-width: 720px) {
    .page {

        &:before {
            content: none;
        }

        .header {
            height: 24vh;
        }

        .map {
            height: 76vh;
            padding: 0;
            width: calc(100% - 0.5rem);
        }
    }
}
