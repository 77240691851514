@import 'constants.scss';

* {
  font-family: 'Lato', sans-serif;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.app {
  background-color: $black;
  color: $white;
  height: 100vh;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  text-align: center;
  width: 100%;
}
