@import '../../constants.scss';

.map-wrapper {
    height: 100%;
    position: relative;
    width: 100%;

    .map-container {
        background-color: $grey_90;
        border: 0.25rem solid $blue;
        border-radius: 0.5rem;
        overflow: hidden;
        height: calc(100% - 0.5rem);
        width: 100%;

        .error {
            background-color: $yellow;
            color: $black;
            display: block;
            padding: 1rem 2rem;
        }
    }

    .last-update {
        align-items: center;
        background-color: $blue;
        border-radius: 0 0.5rem 0 0.5rem;
        bottom: 0;
        display: flex;
        font-weight: 300;
        font-size: 1.5rem;
        justify-content: center;
        left: 0;
        margin: auto;
        padding: 0.5rem 1.5rem;
        position: absolute;
        text-align: center;
        width: 20vw;

        .material-icons {
            margin-right: 0.5rem;
        }
    }
}

@media screen and (max-width: 720px) {
    .map-wrapper {
        .map-container {
            border-radius: 0;
        }
        .last-update {
            border-radius: 0;
            width: calc(100% - 2.5rem);
        }
    }
}
